<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7 text-center" style="color: #1A347F;">
          My Appointments
        </div>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab" active-class="active" centered fixed-tabs>
          <v-tab v-for="(item, index) in items" :key="index" width="160">
            <span style="color: #1A347F;" class="mt-3 fn12-weight7 text-center">
              {{ item.name }}
            </span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, index) in items" :key="index">
            <div
              v-for="(object, index) in item.objects"
              :key="index"
              class="mt-2"
            >
              <v-card
                elevation="4"
                class="rounded-lg mx-auto"
                min-width="280"
                max-width="374"
                max-height="130"
                height="130"
              >
                <v-row class="mt-2" no-gutters>
                  <v-col cols="4" class="py-0 pr-0">
                    <v-img
                      :src="object.image"
                      class="mx-4 my-4"
                      width="76"
                      height="76"
                    />
                  </v-col>
                  <v-col cols="8" class="py-0">
                    <div class="ml-3">
                      <div class="fn14-weight7 mt-4">
                        {{ object.name }}
                      </div>
                      <div class="fn12 mt-2">
                        {{ object.time }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-card-actions v-if="object.status" class="px-2 mt-n5 fn14">
                  <div class="mx-10"></div>
                  <div style="color: #9F9F9F;" class="mx-10">
                    Canceled
                  </div>
                </v-card-actions>
                <v-card-actions
                  v-else
                  class="px-2 mt-n5 fn14"
                  style="font-weight: 600;"
                >
                  <v-spacer></v-spacer>
                  <div style="color: #1A347F;">
                    Click here to start <a-icon type="right" />
                  </div>
                  <div
                    style="color: #E40808;"
                    class="mx-4"
                    @click="dialog = true"
                  >
                    Cancel
                  </div>
                </v-card-actions>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      persistent
      :overlay-opacity="0.9"
      min-width="375"
    >
      <a-icon
        type="close"
        class="text-right white--text"
        @click="dialog = false"
      />
      <v-card
        class="pt-8 pb-2 mx-auto"
        max-width="375"
        min-height="515"
        max-height="515"
      >
        <div class="fn24-weight7 mx-2 text-center" style="color: #1A347F;">
          Are you sure that you want to cancel?
        </div>
        <div>
          <img
            src="@/assets/profile/no1.png"
            class="my-4"
            style="display: block; margin-left: auto; margin-right: auto;"
          />
          <div class="fn16-weight7 text-center" style="color: #1A347F;">
            Dr.Johnna Dekdee
          </div>
          <v-divider class="my-4"></v-divider>
          <v-row no-gutters>
            <v-col cols="6" class="text-center">
              <span class="fn16-weight7">Thu, Dec 18</span>
            </v-col>
            <v-col cols="6" class="text-center">
              <span class="fn16-weight7">14:00 - 15:00</span>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <div
            class="mx-6 fn12-weight7 text-center my-4"
            style="color: #D72626;"
          >
            *You need to schedule a new appointment if you want to consult.
          </div>
        </div>
        <v-btn
          width="270"
          max-width="346"
          height="57"
          dark
          elevation="0"
          class="rounded-lg mx-auto text-capitalize"
          style="background: #FB4040; display: block; margin-left: auto; margin-right: auto;"
          @click="dialog = false"
        >
          Cancel this appointment
        </v-btn>
        <v-btn
          width="270"
          max-width="346"
          height="57"
          elevation="0"
          text
          class="rounded-lg mx-auto text-capitalize"
          @click="dialog = false"
          style="color: #1A347F; display: block; margin-left: auto; margin-right: auto;"
        >
          Back
        </v-btn>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      tab: null,
      dialog: false,
      items: [
        {
          name: "Upcoming",
          objects: [
            {
              name: "Online Consulting with Dr. Johnna Dekdee",
              image: require("@/assets/profile/no1.png"),
              time: "Thu, Dec 18 14:00 - 15:00",
              status: "",
            },
          ],
        },
        {
          name: "History",
          objects: [
            {
              name: "Online Consulting with Dr. Johnna Dekdee",
              image: require("@/assets/profile/no1.png"),
              time: "Thu, Dec 18 14:00 - 15:00",
              status: "Canceled",
            },
          ],
        },
      ],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAllVoucher();
  },
  methods: {
    async getAllVoucher() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/vouchers`
        // 'http://localhost:8081/categories/'
      );
      console.log("getAllVoucher", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    UpdateVoucher(val) {
      localStorage.setItem("Voucherdata", Encode.encode(val));
      this.$router.push("EditVoucher");
    },
    ViewVoucher(val) {
      localStorage.setItem("Voucherdata", Encode.encode(val));
      this.$router.push("ViewVoucher");
    },
    async DeleteVoucher(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiData"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/vouchers/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllVoucher();
        }
      });
    },
    goToCreate() {
      this.$router.push("createVoucher");
    },
  },
};
</script>

<style scoped>
.active {
  color: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
